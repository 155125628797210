import styled from '@emotion/styled';
import QuintilePill from '../QuintilePill';
import { colors, Text } from '@innovationdepartment/proxima-ui';
import { MyCreativeQuickviewModalProps } from './types';

const PerformanceBreakdownWrapper = styled.div`
  display: flex;
  border: 1px solid ${colors.neutral10};
  border-radius: 4px;
  padding: 0px 0px 20px 0px;
  margin-top: 16px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 438px;
`;

const PerformanceBreakdownHeader = styled(Text)`
  background-color: ${colors.neutral0};
  border-radius: 4px 4px 0 0;
  display: flex;
  height: 39px;
  padding: 10px 114px 13px 115px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MetricsRow = styled.div`
  display: flex;
  height: 24px;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > span:first-of-type {
    width: 110px;
  }

  > span:nth-of-type(2) {
    width: 50px;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 16px;
  width: 100%;
`;

const MyCreativeQuickviewPerformanceBreakdown = (
  props: Omit<MyCreativeQuickviewModalProps, 'onClose' | 'open'>
) => {
  const { ad } = props;

  const roasDisplayValue = ad?.purchaseRoas ? `${ad?.purchaseRoas?.toFixed(2)}X` : '--';
  const cpmDisplayValue = ad?.cpm ? `$${ad?.cpm?.toFixed(2)}` : '--';
  const percentDisplayValue = (value: number | undefined) => {
    if (typeof value === 'string') {
      if (value && !Number.isNaN(value)) return `${(+value).toFixed(2)}%`;
    }
    return '--';
  };

  return (
    <PerformanceBreakdownWrapper>
      <PerformanceBreakdownHeader variant="tag1Semibold">
        Performance Breakdown
      </PerformanceBreakdownHeader>
      <MetricsContainer>
        <MetricsRow>
          <Text variant="tag1Semibold" color="neutral70">
            ROAS
          </Text>
          <Text variant="body2Semibold">{roasDisplayValue}</Text>
          <QuintilePill quintile={ad?.purchaseRoasBrandIdQuentile || 0} />
        </MetricsRow>
        <MetricsRow>
          <Text variant="tag1Semibold" color="neutral70">
            CTR
          </Text>
          <Text variant="body2Semibold">{percentDisplayValue(ad?.ctr)}</Text>
          <QuintilePill quintile={ad?.ctrBrandIdQuentile || 0} />
        </MetricsRow>
        <MetricsRow>
          <Text variant="tag1Semibold" color="neutral70">
            Thumbstop
          </Text>
          <Text variant="body2Semibold">{percentDisplayValue(ad?.thumbstopRate)}</Text>
          {ad?.thumbstopRate ? (
            <QuintilePill quintile={ad?.thumbstopRateBrandIdQuentile || 0} />
          ) : (
            <Text variant="body2Semibold">--</Text>
          )}
        </MetricsRow>
        <MetricsRow>
          <Text variant="tag1Semibold" color="neutral70">
            CPM
          </Text>
          <Text variant="body2Semibold">{cpmDisplayValue}</Text>
          <QuintilePill quintile={ad?.cpmBrandIdQuentile || 0} />
        </MetricsRow>
        <MetricsRow>
          <Text variant="tag1Semibold" color="neutral70">
            CPC
          </Text>
          <Text variant="body2Semibold">{percentDisplayValue(ad?.cpc)}</Text>
          <QuintilePill quintile={ad?.cpcBrandIdQuentile || 0} />
        </MetricsRow>
        <MetricsRow>
          <Text variant="tag1Semibold" color="neutral70">
            CVR
          </Text>
          <Text variant="body2Semibold">{percentDisplayValue(ad?.cvr)}</Text>
          <QuintilePill quintile={ad?.cvrBrandIdQuentile || 0} />
        </MetricsRow>
      </MetricsContainer>
    </PerformanceBreakdownWrapper>
  );
};

export default MyCreativeQuickviewPerformanceBreakdown;
